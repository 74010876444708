<template>
  <div>
    <div :class="isPc ? 'pc-containers' : 'mb-containers'">
      <div :class="isPc ? 'pc-contents' : 'mb-contents profile'">
        <v-card-text class="bold-f-s text-center p-t-20">
          <v-row>
            <v-col cols="12">
              <v-btn
                :color="shows === 1 ? 'success' : 'grey'"
                @click="shows = 1"
                outlined
                width="30%"
                class="m-r-10"
                :style="isPc ? '' : 'font-size: 12px !important'"
              >
                profile
              </v-btn>
              <v-btn
                :color="shows === 2 ? 'success' : 'grey'"
                @click="shows = 2"
                outlined
                width="30%"
                class="m-r-10"
                :style="isPc ? '' : 'font-size: 12px !important'"
              >
                employment
              </v-btn>
              <v-btn
                :color="shows === 3 ? 'success' : 'grey'"
                @click="shows = 3"
                outlined
                width="30%"
                :style="isPc ? '' : 'font-size: 12px !important'"
              >
                bank
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <!-- profile   -->
          <v-card class="overflow-y-auto overflow-x-hidden" flat width="100%" style="height: 512px" v-if="shows === 1">
            <div>
              <v-row>
                <v-col v-for="(field, index) in name" :key="index" cols="4">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-text-field
                      class="input-text-uppercase"
                      height="20px"
                      type="input"
                      :label="field.title"
                      :error-messages="errors[0]"
                      v-model="personal[field.filedKey]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="(field, index) in ssnAndBirthday" :key="index" cols="6">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-text-field
                      class="f-s-20"
                      v-if="field.filedKey === 'ssn'"
                      height="20px"
                      type="input"
                      disabled
                      :label="field.title"
                      v-model="personal[field.filedKey]"
                      v-mask="field.mask"
                      :error-messages="errors[0]"
                    ></v-text-field>
                    <v-menu
                      v-else
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="personal[field.filedKey]"
                          height="20px"
                          :label="field.title"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :error-messages="errors[0]"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="updateBirthday()"> OK </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="(field, index) in phone" :key="index" cols="6">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-text-field
                      class="f-s-20"
                      height="20px"
                      type="input"
                      :label="field.title"
                      v-model="personal[field.filedKey]"
                      v-mask="field.mask"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="(field, index) in email" :key="index" cols="6">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-text-field
                      class="f-s-20 input-text-lowercase"
                      height="20px"
                      type="input"
                      :label="field.title"
                      :error-messages="errors[0]"
                      v-model="personal[field.filedKey]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="(field, index) in location" :key="index" cols="4">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-select
                      v-if="field.filedKey === 'state'"
                      height="20px"
                      :items="field.options[field.filedKey]"
                      :label="field.title"
                      v-model="personal[field.filedKey]"
                      :error-messages="errors[0]"
                    ></v-select>
                    <v-text-field
                      v-else-if="field.filedKey === 'city'"
                      class="f-s-20 input-text-capitalize"
                      height="20px"
                      type="input"
                      :label="field.title"
                      v-model="personal[field.filedKey]"
                      :error-messages="errors[0]"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      class="f-s-20 input-text-capitalize"
                      height="20px"
                      type="input"
                      :label="field.title"
                      :error-messages="errors[0]"
                      v-model="personal[field.filedKey]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="(field, index) in address" :key="index" cols="12">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-text-field
                      class="f-s-20 input-text-capitalize"
                      height="20px"
                      type="input"
                      :label="field.title"
                      v-model="personal[field.filedKey]"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <!-- employment  -->
          <v-card class="overflow-y-auto overflow-x-hidden" flat width="100%" style="height: 512px" v-if="shows === 2">
            <div>
              <v-row>
                <v-col v-for="(field, index) in employer" :key="index" cols="6">
                  <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                    <v-select
                      v-if="field.filedKey === 'paydayOnHoliday'"
                      height="20px"
                      :items="field.options[field.filedKey]"
                      :label="field.title"
                      v-model="employment[field.filedKey]"
                      :error-messages="errors[0]"
                    ></v-select>
                    <v-text-field
                      v-else-if="field.filedKey === 'employerPhone'"
                      height="20px"
                      :label="field.title"
                      v-mask="employmentMask"
                      v-model="employment[field.filedKey]"
                      :error-messages="errors[0]"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      height="20px"
                      :label="field.title"
                      v-model="employment[field.filedKey]"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <!--    bank-->
          <v-card class="overflow-y-auto overflow-x-hidden" flat width="100%" style="height: 512px" v-if="shows === 3">
            <div>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    class="f-s-20"
                    height="20px"
                    type="input"
                    label="Bank"
                    :rules="[rules.required]"
                    v-model="bankData.bankName"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="f-s-20"
                    height="20px"
                    type="input"
                    :rules="[rules.required, rules.routingNo]"
                    label="Routing Number"
                    v-model="bankData.routingNo"
                    v-mask="bankMask"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="f-s-20"
                    height="20px"
                    type="input"
                    :rules="[rules.required, rules.accountNo, rules.number]"
                    label="Account Number"
                    v-model="bankData.accountNo"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-card-text>
      </div>
      <v-card-actions :class="isPc ? 'pc-pages-action' : 'mb-pages-action'">
        <v-btn large color="primary" :width="isPc ? '240' : '96vw'" class="m-0 f-s-18" @click="next"> Next </v-btn>
      </v-card-actions>
      <v-snackbar color="error" v-model="snackbar.display" multi-line>
        <v-card-text class="p-0" style="font-size: 24px">{{ snackbar.message }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.display = false">
            <v-card-text class="p-0" style="font-size: 26px">Close</v-card-text>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Bank from '../../views/wap/Bank'
import Profile from '../../views/web/Profile'
import Employment from '../../views/wap/Employment'
import { DataType, Tool } from '@/util/index.js'
import { OriginationApi } from '../../api/application'
const tool = new Tool()
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ValidationProvider, Employment, Profile, Bank },
  props: {
    isPc: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      page: DataType.PAGES.BASIC_INFO,
      shows: 1,
      employer: [
        { title: 'Employer Name', filedKey: 'employerName', rules: 'required' },
        { title: 'Employer Phone Number', filedKey: 'employerPhone', rules: 'phone' },
        { title: 'Job Title', filedKey: 'jobTitle', rules: 'required' },
        {
          title: 'Please select your Payday on Holiday',
          filedKey: 'paydayOnHoliday',
          options: { paydayOnHoliday: DataType.PaydayOnHolidayType },
          rules: 'required'
        }
      ],
      employment: {
        employerName: '',
        jobTitle: '',
        employerPhone: '',
        paydayOnHoliday: ''
      },
      employmentMask: '',
      bankMask: '#########',
      phone: [
        { title: 'Home Phone', filedKey: 'homePhone', mask: '(###) ###-####', rules: 'phone' },
        { title: 'Cell Phone', filedKey: 'cellPhone', mask: '(###) ###-####', rules: 'phone' }
      ],
      name: [
        { title: 'First Name', filedKey: 'firstName', rules: 'required' },
        { title: 'Middle Name', filedKey: 'middleName', rules: 'required' },
        { title: 'Last Name', filedKey: 'lastName', rules: 'required' }
      ],
      ssnAndBirthday: [
        { title: 'Date of Birth', filedKey: 'birthday', rules: 'required' },
        { title: 'SSN', filedKey: 'ssn', mask: '***-**-####' }
      ],
      email: [
        { title: 'Primary Email', filedKey: 'email', rules: 'email' },
        { title: 'Alternative Email', filedKey: 'alternateEmail', rules: 'email' }
      ],
      location: [
        { title: 'State', filedKey: 'state', options: { state: DataType.State }, rules: 'required' },
        { title: 'City', filedKey: 'city', rules: 'required' },
        { title: 'Zip', filedKey: 'zip', rules: 'zip' }
      ],
      address: [
        { title: 'Address', filedKey: 'address', rules: 'required' }
      ],
      bankData: {
        bankName: 'Wells Fargo',
        routingNo: '',
        accountNo: ''
      },
      rules: {
        required: value => !!value || 'Required',
        routingNo: value => {
          return value.toString().length === 9 || 'Invalid Routing Number'
        },
        accountNo: value => {
          return (value.toString().length >= 4 && value.toString().length <= 17) || 'Invalid Account Number'
        },
        number: value => {
          return /^\d+$/.test(value) || 'Account # should be a digit'
        }
      },
      snackbar: {
        display: false
      },
      sourcePersonal: {},
      personal: {
        firstName: '',
        middleName: '',
        lastName: '',
        birthday: '',
        ssn: '',
        city: '',
        zip: '',
        address: '',
        state: '',
        email: '',
        alternateEmail: '',
        cellPhone: '',
        homePhone: ''
      },
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      sourceBank: {}
    }
  },
  methods: {
    verifyCustomerInformation () {
      const _this = this
      const firstName = _this.personal.firstName
      if (tool.isEmpty(firstName)) {
        _this.snackbar.message = 'Invalid First Name!'
        _this.shows = 1
        return false
      }
      const lastName = _this.personal.lastName
      if (tool.isEmpty(lastName)) {
        _this.snackbar.message = 'Invalid Last Name!'
        _this.shows = 1
        return false
      }
      const homePhone = _this.personal.homePhone
      if (homePhone.length !== 14) {
        _this.snackbar.message = 'Invalid Home Phone!'
        _this.shows = 1
      }
      const cellPhone = _this.personal.cellPhone
      if (cellPhone.length !== 14) {
        _this.snackbar.message = 'Invalid Cell Phone!'
        _this.shows = 1
        return false
      }
      const email = _this.personal.email
      const city = _this.personal.city
      const zip = _this.personal.zip
      const address = _this.personal.address
      if (tool.isEmpty(email)) {
        _this.snackbar.message = 'Invalid Email!'
        _this.shows = 1
        return false
      }
      if (tool.isEmpty(city)) {
        _this.snackbar.message = 'Invalid city!'
        _this.shows = 1
        return false
      }
      if (tool.isEmpty(zip) || zip.length !== 5) {
        _this.snackbar.message = 'Invalid zip!'
        _this.shows = 1
        return false
      }
      if (tool.isEmpty(address)) {
        _this.snackbar.message = 'Invalid address!'
        _this.shows = 1
        return false
      }
      return true
    },
    verifyEmploymentInformation () {
      const _this = this
      const employerName = _this.employment.employerName
      if (tool.isEmpty(employerName)) {
        _this.snackbar.message = 'Invalid Employer Name!'
        _this.snackbar.display = true
        _this.shows = 2
        return false
      }
      const jobTitle = _this.employment.jobTitle
      if (tool.isEmpty(jobTitle) || jobTitle === 'Unknown') {
        _this.snackbar.message = 'Invalid Job Title!'
        _this.snackbar.display = true
        _this.shows = 2
        return false
      }
      const employerPhone = _this.employment.employerPhone.replace(/[^0-9]/ig, '')
      if (employerPhone.length !== 10) {
        _this.snackbar.message = 'Invalid Employer Phone Number!'
        _this.snackbar.display = true
        _this.shows = 2
        return false
      }
      const paydayOnHoliday = _this.employment.paydayOnHoliday
      if (tool.isEmpty(paydayOnHoliday)) {
        _this.snackbar.message = 'Invalid Payday on Holiday!'
        _this.snackbar.display = true
        _this.shows = 2
        return false
      }
      return true
    },
    checkPersonalState (updatePersonal) {
      const _this = this
      const dataStr = {
        loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        checkJson: {
          state: _this.personal.state
        }
      }
      OriginationApi.checkPersonalState(dataStr, function () {
        _this.doUpdate(updatePersonal)
      }, function () {
        if (tool.isNotEmpty(updatePersonal)) {
          const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
          updatePersonal.loanId = loanId
          delete updatePersonal.ssn
          OriginationApi.updateOrigination({ feature: 'personal', data: updatePersonal }, function () {
            _this.checkEmploymentData()
          }, function (error) {
            _this.getErrorCode(error.code)
            _this.shows = 1
          })
        }
        const code = ['4CEA288ECFC282EB862726283F92EB98']
        const statusData = {
          feature: 'loan-status',
          data: {
            loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            loanStatus: DataType.LoanStatus.POSITIVE_WITHDRAWN.value,
            withdrawCode: DataType.WithdrawCodeEnum.NO_BUSINESS_STATE.value,
            withdrawnReasonText: DataType.WithdrawCodeEnum.NO_BUSINESS_STATE.text,
            denyCode: JSON.stringify(code)
          }
        }
        OriginationApi.updateOrigination(statusData, function () {
        }, function () {
        })
        _this.$router.push('/instant/notification/-400')
      })
    },
    getErrorCode (errorCode) {
      switch (errorCode) {
        case DataType.UpdateErrorCode.ZIP_CODE_AND_STATE_ERROR_CODE.value:
          this.$swal({
            type: 'warning',
            text: 'The zip code is not matched with state, please update one of them!',
            showConfirmButton: true,
            confirmButtonText: 'OK'
          }).then(() => {
            this.$store.commit('setOverlay', false)
          })
          break
        case DataType.UpdateErrorCode.MIL_AND_GOV_EMAIL_ERROR_CODE.value:
          this.$router.push('/instant/notification/-400')
          break
        default:
          this.$router.push('/instant/notification/-400')
          break
      }
    },
    doUpdate (updatePersonal) {
      const _this = this
      if (tool.isNotEmpty(updatePersonal)) {
        const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
        updatePersonal.loanId = loanId
        delete updatePersonal.ssn
        OriginationApi.updateOrigination({ feature: 'personal', data: updatePersonal }, function (result) {
          localStorage.setItem(DataType.COOKIE_KEY.PERSONAL, JSON.stringify(_this.sourcePersonal))
          const stepMessage = {
            currect: DataType.PAGES.PERSONAL,
            next: DataType.PAGES.EMPLOYMENT
          }
          _this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            loanId,
            DataType.PAGES.PERSONAL.step,
            DataType.PAGES.EMPLOYMENT.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.checkEmploymentData()
        }, function (error) {
          _this.getErrorCode(error.code)
          _this.shows = 1
        })
      } else {
        const stepMessage = {
          currect: DataType.PAGES.PERSONAL,
          next: DataType.PAGES.EMPLOYMENT
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.PERSONAL.step,
          DataType.PAGES.EMPLOYMENT.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
      }
      _this.$store.commit('setOverlay', false)
    },
    checkPersonalData () {
      const _this = this
      _this.$store.commit('setOverlay', true)
      if (!_this.verifyCustomerInformation()) {
        _this.$store.commit('setOverlay', false)
        _this.snackbar.display = true
        return false
      }
      const updatePersonal = {}
      for (const key in this.personal) {
        let value = this.personal[key]
        if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
          if (value) {
            value = value.toUpperCase()
          }
        }
        if (key === 'homePhone' || key === 'cellPhone') {
          if (value) {
            value = value.replace(/[^0-9]/ig, '')
          }
        }
        if (key === 'email') {
          if (value) {
            value = value.toLowerCase()
          }
        }
        if (key === 'state' || key === 'zip' || key === 'email') {
          if (value) {
            updatePersonal[key] = value
          }
        }
        if (value !== this.sourcePersonal[key]) {
          updatePersonal[key] = value
          this.sourcePersonal[key] = value
        }
      }
      if (tool.isEmpty(this.personal.address.replace(/[0-9]/ig, ''))) {
        this.$swal({
          type: 'info',
          text: 'The address is full of numbers!',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        }).then((res) => {
          if (res.value) {
            _this.checkPersonalState(updatePersonal)
          } else {
            _this.$store.commit('setOverlay', false)
          }
        })
      } else {
        _this.checkPersonalState(updatePersonal)
      }
    },
    checkEmploymentData () {
      const _this = this
      if (!_this.verifyEmploymentInformation()) {
        this.$store.commit('setOverlay', false)
        return false
      }
      const updateEmployment = {}
      for (const key in _this.employment) {
        let value = _this.employment[key]
        if (key === 'employerPhone') {
          value = value.replace(/[^0-9]/ig, '')
        }
        if (value !== this.sourceEmployment[key]) {
          updateEmployment[key] = value
        }
      }
      if (tool.isEmpty(updateEmployment)) {
        const stepMessage = {
          currect: DataType.PAGES.EMPLOYMENT,
          next: DataType.PAGES.BANK
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.EMPLOYMENT.step,
          DataType.PAGES.BANK.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.checkBankData()
      } else {
        updateEmployment.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
        OriginationApi.updateOrigination({ feature: 'employment', data: updateEmployment }, function (result) {
          const stepMessage = {
            currect: DataType.PAGES.EMPLOYMENT,
            next: DataType.PAGES.BANK
          }
          _this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.EMPLOYMENT.step,
            DataType.PAGES.BANK.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.checkBankData()
        }, function () {
        })
      }
    },
    checkBankData () {
      const _this = this
      const updateBank = {}
      for (const key in _this.bankData) {
        const value = _this.bankData[key].trim()
        if (key === 'routingNo' && value.length !== 9) {
          return false
        }
        if (key === 'accountNo' && (value.length < 4 && value.length > 17)) {
          return false
        }
        if (value !== this.sourceBank[key]) {
          updateBank[key] = this.bankData[key]
        }
      }
      _this.$store.commit('setOverlay', false)
      if (tool.isEmpty(updateBank)) {
        const stepMessage = {
          currect: DataType.PAGES.BANK,
          next: DataType.PAGES.IBV
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.BANK.step,
          DataType.PAGES.LOAN_AMOUNT.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.$router.push(DataType.PAGES.DL_IF.addressPC)
      } else {
        updateBank.loanId = this.sourceBank.loanId
        OriginationApi.updateOrigination({ feature: 'bank', data: updateBank }, function () {
          _this.$store.commit('setOverlay', false)
          const stepMessage = {
            currect: DataType.PAGES.BANK,
            next: DataType.PAGES.IBV
          }
          _this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.BANK.step,
            DataType.PAGES.LOAN_AMOUNT.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.$router.push(DataType.PAGES.DL_IF.addressPC)
        }, function (result) {
          if (result.code === -20002) {
            _this.$router.push('/instant/notification/-100')
          }
          if (tool.isNotEmpty(result.code) && result.code === -83010300) {
            const code = '4CEA288ECFC282EB862726283F92EB98'
            OriginationApi.updateOrigination({
              feature: 'loan-status',
              data: {
                loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
                loanStatus: DataType.LoanStatus.POSITIVE_WITHDRAWN.value,
                withdrawCode: DataType.WithdrawCodeEnum.BAD_BANK_BAD_ABA.value,
                withdrawnReasonText: DataType.WithdrawCodeEnum.BAD_BANK_BAD_ABA.text,
                denyCode: JSON.stringify(code)
              }
            }, function () {
              _this.$router.push('/instant/notification/-400')
            })
          }
        })
      }
    },
    next () {
      this.checkPersonalData()
    }
  },
  created () {
    const _this = this
    _this.$store.commit('setCurrentPage', this.page)
    _this.$store.commit('setOverlay', true)
    _this.sourcePersonal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    for (const key in this.personal) {
      const value = this.sourcePersonal[key]
      // if (key === 'birthday') {
      //   value = new Date(value).format('yyyy-MM-dd')
      // }
      if (key === 'ssn') {
        _this.personal[key] = value.substr(5)
      } else {
        _this.personal[key] = value
      }
    }
    _this.date = new Date(_this.personal.birthday).toISOString().substr(0, 10)

    /* employment init */
    _this.$store.commit('setCurrentPage', _this.page)
    // TODO get employment information
    OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'EMPLOYMENT'
    }, function (result) {
      _this.sourceEmployment = result
      for (const key in _this.employment) {
        _this.employment[key] = result[key]
      }
      if (result.jobTitle === 'Unknown') {
        _this.employment.jobTitle = ''
      }
      _this.overlay = false
    }, function (res) {
    })
    _this.employmentMask = '(###) ###-####'

    /* bank init */
    OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'BANK'
    }, function (result) {
      _this.sourceBank = result
      for (const key in _this.bankData) {
        _this.bankData[key] = result[key]
      }
      _this.overlay = false
    }, function (res) {
    })

    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))

    this.$store.commit('setOverlay', false)
  }
}
</script>
<style lang="css">
.profile input {
  text-align: left !important;
  font-size: 16px !important;
}

.profile .input-text-uppercase input {
  text-transform: uppercase;
}

.profile .input-text-lowercase input {
  text-transform: lowercase;
}

.profile .input-text-capitalize input {
  text-transform: capitalize;
}
</style>
