<template>
  <div>
    <v-card-text class="bold-f-s text-center p-t-24 pc-title">
      Please confirm/update your information
    </v-card-text>
    <div class="pc-container">
      <div class="pc-content">
        <v-row class="m-0">
          <v-col v-for="(field, index) in name" :key="index"
                 cols="4">
            <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
              <v-text-field
                class="input-text-uppercase"
                height="20px"
                type="input"
                :label="field.title"
                :error-messages="errors[0]"
                v-model="personal[field.filedKey]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="m-0">
          <v-col v-for="(field, index) in ssnAndBirthday" :key="index"
                 cols="6">
            <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
              <v-text-field
                class="f-s-20"
                v-if="field.filedKey === 'ssn'"
                height="20px"
                type="input"
                disabled
                :label="field.title"
                v-model="personal[field.filedKey]"
                v-mask="field.mask"
                :error-messages="errors[0]"
              ></v-text-field>
              <v-menu
                v-else
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="personal[field.filedKey]"
                    height="20px"
                    :label="field.title"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    :error-messages="errors[0]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                >
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="updateBirthday()"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="m-0">
          <v-col v-for="(field, index) in phone" :key="index" cols="6">
            <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
              <v-text-field
                class="f-s-20"
                height="20px"
                type="input"
                :label="field.title"
                v-model="personal[field.filedKey]"
                v-mask="field.mask"
                :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="m-0">
          <v-col v-for="(field, index) in email" :key="index"
                 cols="6">
            <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
              <v-text-field
                class="f-s-20 input-text-lowercase"
                height="20px"
                type="input"
                :label="field.title"
                :error-messages="errors[0]"
                v-model="personal[field.filedKey]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="m-0">
          <v-col v-for="(field, index) in location" :key="index"
                 cols="4">
            <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
              <v-select
                v-if="field.filedKey==='state'"
                height="20px"
                :items="field.options[field.filedKey]"
                :label="field.title"
                v-model="personal[field.filedKey]"
                :error-messages="errors[0]"
              ></v-select>
              <v-text-field
                v-else-if="field.filedKey==='city'"
                class="f-s-20 input-text-capitalize"
                height="20px"
                type="input"
                :label="field.title"
                v-model="personal[field.filedKey]"
                :error-messages="errors[0]"
              ></v-text-field>
              <v-text-field
                v-else
                class="f-s-20 input-text-capitalize"
                height="20px"
                type="input"
                :label="field.title"
                :error-messages="errors[0]"
                v-model="personal[field.filedKey]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="m-0">
          <v-col v-for="(field, index) in address" :key="index"
                 cols="12">
            <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
              <v-text-field
                class="f-s-20 input-text-capitalize"
                height="20px"
                type="input"
                :label="field.title"
                v-model="personal[field.filedKey]"
                :error-messages="errors[0]"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-card-actions class="">
      <v-btn
        large
        color="primary"
        width="240"
        class="m-0 f-s-18"
        @click="next">
        Next
      </v-btn>
    </v-card-actions>

    <v-snackbar
      color="error"
      v-model="snackbar.display"
      multi-line
    >
      <v-card-text class="p-0" style="font-size: 24px">{{snackbar.message}}</v-card-text>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.display = false"
        >
          <v-card-text class="p-0" style="font-size: 26px">Close</v-card-text>
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { DataType, Tool } from '../../js/core'
import { OriginationApi } from '../../api/application'

const tool = new Tool()
export default {
  components: { ValidationProvider },
  data () {
    return {
      page: DataType.PAGES.PERSONAL,
      snackbar: {
        display: false
      },
      showModal: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      phone: [
        { title: 'Home Phone', filedKey: 'homePhone', mask: '(###) ###-####', rules: 'phone' },
        { title: 'Cell Phone', filedKey: 'cellPhone', mask: '(###) ###-####', rules: 'phone' }
      ],
      name: [
        { title: 'First Name', filedKey: 'firstName', rules: 'required' },
        { title: 'Middle Name', filedKey: 'middleName', rules: 'required' },
        { title: 'Last Name', filedKey: 'lastName', rules: 'required' }
      ],
      ssnAndBirthday: [
        { title: 'Date of Birth', filedKey: 'birthday', rules: 'required' },
        { title: 'SSN', filedKey: 'ssn', mask: '***-**-####' }
      ],
      email: [
        { title: 'Primary Email', filedKey: 'email', rules: 'email' },
        { title: 'Alternative Email', filedKey: 'alternateEmail', rules: 'email' }
      ],
      location: [
        { title: 'State', filedKey: 'state', options: { state: DataType.State }, rules: 'required' },
        { title: 'City', filedKey: 'city', rules: 'required' },
        { title: 'Zip', filedKey: 'zip', rules: 'zip' }
      ],
      address: [
        { title: 'Address', filedKey: 'address', rules: 'required' }
      ],
      sourcePersonal: {},
      personal: {
        firstName: '',
        middleName: '',
        lastName: '',
        birthday: '',
        ssn: '',
        city: '',
        zip: '',
        address: '',
        state: '',
        email: '',
        alternateEmail: '',
        cellPhone: '',
        homePhone: ''
      }
    }
  },
  created () {
    const _this = this
    _this.$store.commit('setCurrentPage', this.page)
    _this.$store.commit('setOverlay', true)
    _this.sourcePersonal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    for (const key in this.personal) {
      const value = this.sourcePersonal[key]
      // if (key === 'birthday') {
      //   value = new Date(value).format('yyyy-MM-dd')
      // }
      if (key === 'ssn') {
        _this.realSSN = value
        _this.personal[key] = value.substr(5)
      } else {
        _this.personal[key] = value
      }
    }
    _this.date = new Date(_this.personal.birthday).toISOString().substr(0, 10)

    // 跳转页面
    OriginationApi.pageControl(null, function (pageControl) {
      if (tool.isNotEmpty(pageControl) && pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0) {
        _this.page.next = 'EMPLOYMENT'
      }
    })

    _this.$store.commit('setOverlay', false)
  },
  methods: {
    verifyCustomerInformation () {
      const _this = this
      const firstName = _this.personal.firstName
      if (tool.isEmpty(firstName)) {
        _this.snackbar.message = 'Invalid First Name!'
        return false
      }
      const lastName = _this.personal.lastName
      if (tool.isEmpty(lastName)) {
        _this.snackbar.message = 'Invalid Last Name!'
        return false
      }
      const homePhone = _this.personal.homePhone
      if (homePhone.length !== 14) {
        _this.snackbar.message = 'Invalid Home Phone!'
      }
      const cellPhone = _this.personal.cellPhone
      if (cellPhone.length !== 14) {
        _this.snackbar.message = 'Invalid Cell Phone!'
        return false
      }
      const email = _this.personal.email
      const city = _this.personal.city
      const zip = _this.personal.zip
      const address = _this.personal.address
      if (tool.isEmpty(email)) {
        _this.snackbar.message = 'Invalid Email!'
        return false
      }
      if (tool.isEmpty(city)) {
        _this.snackbar.message = 'Invalid city!'
        return false
      }
      if (tool.isEmpty(zip) || zip.length !== 5) {
        _this.snackbar.message = 'Invalid zip!'
        return false
      }
      if (tool.isEmpty(address)) {
        _this.snackbar.message = 'Invalid address!'
        return false
      }
      return true
    },
    next () {
      const _this = this
      _this.showModal = false
      _this.$store.commit('setOverlay', true)
      if (!_this.verifyCustomerInformation()) {
        _this.$store.commit('setOverlay', false)
        _this.snackbar.display = true
        return false
      }
      const updatePersonal = {}
      for (const key in this.personal) {
        let value = this.personal[key]
        if (key === 'firstName' || key === 'middleName' || key === 'lastName') {
          if (value) {
            value = value.toUpperCase()
          }
        }
        if (key === 'homePhone' || key === 'cellPhone') {
          if (value) {
            value = value.replace(/[^0-9]/ig, '')
          }
        }
        if (key === 'email') {
          if (value) {
            value = value.toLowerCase()
          }
        }
        if (value !== this.sourcePersonal[key]) {
          updatePersonal[key] = value
          this.sourcePersonal[key] = value
        }
      }
      if (tool.isEmpty(this.personal.address.replace(/[0-9]/ig, ''))) {
        this.$swal({
          type: 'info',
          text: 'The address is full of numbers!',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        }).then((res) => {
          if (res.value) {
            _this.checkPersonalState(updatePersonal)
          } else {
            _this.$store.commit('setOverlay', false)
          }
        })
      } else {
        _this.checkPersonalState(updatePersonal)
      }
      _this.$eventBus.$emit('closeCountDown')
    },
    updateBirthday () {
      this.personal.birthday = this.date
      this.menu = false
    },
    checkPersonalState (updatePersonal) {
      const _this = this
      const dataStr = {
        loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        checkJson: {
          state: _this.personal.state
        }
      }
      OriginationApi.checkPersonalState(dataStr, function () {
        _this.doUpdate(updatePersonal)
      }, function () {
        if (tool.isNotEmpty(updatePersonal) && Object.prototype.hasOwnProperty.call(updatePersonal, 'ssn')) {
          delete updatePersonal.ssn
        }

        if (tool.isNotEmpty(updatePersonal)) {
          const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
          updatePersonal.loanId = loanId
          OriginationApi.updateOrigination({ feature: 'personal', data: updatePersonal }, function () {
          }, function () {
            _this.$router.push('/instant/notification/-400')
          })
        }
        const code = ['4CEA288ECFC282EB862726283F92EB98']
        const statusData = {
          feature: 'loan-status',
          data: {
            loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            loanStatus: DataType.LoanStatus.NEGATIVE_WITHDRAWN.value,
            withdrawCode: DataType.WithdrawCodeEnum.NO_BUSINESS_STATE.value,
            withdrawnReasonText: DataType.WithdrawCodeEnum.NO_BUSINESS_STATE.text,
            denyCode: JSON.stringify(code)
          }
        }
        OriginationApi.updateOrigination(statusData, function () {
        }, function () {
        })
        _this.$router.push('/instant/notification/-400')
      })
    },
    doUpdate (updatePersonal) {
      const _this = this
      if (tool.isNotEmpty(updatePersonal) && Object.prototype.hasOwnProperty.call(updatePersonal, 'ssn')) {
        delete updatePersonal.ssn
      }

      if (tool.isNotEmpty(updatePersonal)) {
        const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
        updatePersonal.loanId = loanId
        OriginationApi.updateOrigination({ feature: 'personal', data: updatePersonal }, function (result) {
          localStorage.setItem(DataType.COOKIE_KEY.PERSONAL, JSON.stringify(_this.sourcePersonal))
          _this.$store.commit('setOverlay', false)
          const stepMessage = {
            currect: DataType.PAGES.PERSONAL,
            next: DataType.PAGES.MILITARY
          }
          _this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            loanId,
            DataType.PAGES.PERSONAL.step,
            DataType.PAGES[_this.page.next].step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.$router.push(DataType.PAGES[_this.page.next].addressPC)
        }, function () {
          _this.$router.push('/instant/notification/-400')
        })
      } else {
        const stepMessage = {
          currect: DataType.PAGES.PERSONAL,
          next: DataType.PAGES.MILITARY
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.PERSONAL.step,
          DataType.PAGES[_this.page.next].step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.$router.push(DataType.PAGES[_this.page.next].addressPC)
      }
    }
  }
}
</script>
<style lang="css">
  .profile input {
    text-align: left !important;
    font-size: 16px !important;
  }

  .profile .input-text-uppercase input {
    text-transform: uppercase;
  }

  .profile .input-text-lowercase input {
    text-transform: lowercase;
  }

  .profile .input-text-capitalize input {
    text-transform: capitalize;
  }
</style>
